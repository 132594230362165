<template>
  <LandingLayout>
    <div class="tw-flex tw-items-center tw-justify-center tw-py-10">
      <h1 class="tw-font-bold tw-text-4xl">Kebijakan Privasi</h1>
    </div>
    <div class="tw-flex tw-flex-col tw-py-10 tw-px-48 tw-space-y-8 tw-text-xl tw-mb-20">
      <p>
        PT Samden Cipta Digital atau “Salonial” berkomitmen untuk menjaga
        kerahasiaan informasi dan data-data pengguna Aplikasi Salonial yang akan
        maupun telah melakukan verifikasi akun,
      </p>
      <p>
        Kebijakan Privasi dalam Aplikasi ini bertujuan untuk memberikan
        informasi kepada para pengguna Aplikasi Salonial mengenai informasi akun
        dan data-data pengguna serta data-data yang diterima atau dikumpulkan
        langsung dari pengguna maupun data-data yang diserahkan pada saat
        Pengguna membuat atau memperbarui akun di Aplikasi Salonial, mengoreksi
        data, mengisi survei, melakukan transaksi atau pemesanan, pembayaran,
        memberikan penilaian serta hal-hal lain yang membutuhkan informasi dari
        pengguna, data-data yang terekam pada saat pengguna bertransaksi dan
        mempergunakan Aplikasi Salonial serta akitivitas-aktivitas lain yang
        terdapat di dalam atau yang berkaitan dengan Aplikasi Salonial atau yang
        dikelola oleh Salonial termasuk namun tidak terbatas pada data-data yang
        diperoleh dari sumber-sumber lain. Data-data Pengguna yang diperoleh
        dapat disimpan oleh Salonial walaupun akun Pengguna sudah ditutup atau
        sudah tidak terdaftar, baik atas kemauan pengguna sendiri maupun oleh
        karena hal-hal lain.
      </p>
      <p>
        Salonial dapat menggunakan data-data yang diperoleh dan dikumpulkan dari
        Pengguna tersebut untuk pendaftaran akun, verifikasi serta semua
        kegiatan yang bertujuan untuk mempermudah dalam memverifikasi akun
        penggguna, untuk memproses transaksi pengguna, memperlancar proses
        penggunaan Aplikasi, mengelola atau menindaklanjuti transaksi ataupun
        layanan yang diinginkan para pengguna dan, berkomunikasi dengan Salonial
        dan mitra-mitra usaha Salonial, untuk meningkatkan kualitas pelayan,
        investigasi terhadap transaksi-transaksi mencurigakan atau yang
        terindikasi mengandung unsur kecurangan atau pelanggaran terhadap
        ketentuan Aplikasi Salonial maupun hukum yang berlaku, serta
        tujuan-tujuan lainnya selama diizinkan oleh peraturan perundang-undangan
        yang berlaku.
      </p>
      <p>
        Salonial tidak akan menjual, berbagi, memperlihatkan, mengalihkan, dan
        meminjamkan data-data pengguna kepada pihak ketiga atas informasi yang
        berkaitan dengan data-data pengguna apliikasi kepada pihak lain tanpa
        persetujuan dari pengguna, kecuali dalam hal-hal :
      </p>
      <ul class="pl-5 tw-list-disc">
        <li class="mb-3">
          Dibutuhkan adanya pengungkapan data-data pengguna kepada mitra atau
          pihak ketiga lain yang membantu Salonial dalam menyajikan layanan yang
          tersedia atau layanan yang di kemudian hari akan tersedia pada
          Aplikasi Salonial dan memproses transaksi pengguna dalam Aplikasi
          Salonial, termasuk memproses transaksi, verifikasi pembayaran, dan
          lain-lain.
        </li>
        <li class="mb-3">
          Diperlukan komunikasi antara mitra usaha Salonial (seperti penyedia
          fasilitas pembayaran, dan penyedia fitur atau fasilitas layanan
          lainnya) dengan pengguna dalam hal penyelesaian kendala maupun hal-hal
          lainnya.
        </li>
        <li class="mb-3">
          Salonial dapat menyediakan data-data pengguna kepada konsultan, mitra
          usaha serta dalam rangka pemeliharaan dan peningkatan kualitas layanan
          Salonial.
        </li>
        <li class="mb-3">
          Pengguna berkomunikasi dengan pihak Salonial melalui media publik
          seperti Instagram, media-media sosial, dan lain-lain, di mana
          komunikasi tersebut dapat dilihat dan diketahui oleh umum.
        </li>
        <li class="mb-3">
          Penilaian yang diberikan terhadap dan oleh mitra usaha yang dapat
          dilihat dan diketahui oleh mitra-mitra usaha dan pengguna-pengguna
          lainnya.
        </li>
        <li class="mb-3">
          Dalam keadaan tertentu, Salonial mungkin perlu untuk menggunakan
          ataupun mengungkapkan data-data pengguna untuk tujuan penegakan hukum,
          pemenuhan persyaratan dan kewajiban peraturan perundang-undangan yang
          berlaku, termasuk dalam hal terjadinya suatu sengketa atau proses
          hukum, atau dugaan tindak pidana dalam upaya mematuhi kewajiban hukum
          dan/atau adanya permintaan yang sah dari aparat penegak hukum atau
          instansi penyelenggara negara yang berwenang, termasuk pemenuhan
          persyaratan dan kewajiban peraturan perundang-undangan yang berlaku
          sehubungan dengan kepentingan perpajakan di Indonesia.
        </li>
        <li class="mb-3">
          Pengguna memiliki kesempatan untuk memberikan penilaian dan ulasan
          dimana informasi ini mungkin dapat dilihat secara publik
        </li>
      </ul>
      <p>
        Pengguna wajib menjaga kerahasiaan kata kunci atau password dan Kode
        Identitas Pengguna atau user-id masing-masing. Salonial tidak
        bertanggung jawab apabila terjadi sesuatu yang merugikan yang disebabkan
        oleh kelalaian pengguna.
      </p>
      <p>
        Salonial dapat sewaktu-waktu melakukan perubahan atau pembaruan terhadap
        Kebijakan Privasi ini setiap saat tanpa pemberitahuan terlebih dahulu
        kepada para pengguna.
      </p>
    </div>
  </LandingLayout>
</template>

<script>
import LandingLayout from "@/layouts/LandingLayout";
export default {
  name: "TermAndCondition",
  components: { LandingLayout },
};
</script>

<style scoped></style>
